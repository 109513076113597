import * as actionTypes from "./actionTypes";
import { firestore } from "../../firebase";

export const authStartLoading = () => {
  return {
    type: actionTypes.AUTH_START_LOADING,
  };
};

export const authStopLoading = () => {
  return {
    type: actionTypes.AUTH_STOP_LOADING,
  };
};

export const authUpdateState = (authData) => {
  return {
    type: actionTypes.AUTH_UPDATE_STATE,
    authData: authData,
  };
};

export const authSetHourlyRate = (hourlyRate, currency, name) => {
  return {
    type: actionTypes.AUTH_SET_HOURLY_RATE,
    hourlyRate: hourlyRate,
    currency: currency,
    name: name,
  };
};

export const authStoreHourlyRate = (
  userId,
  hourlyRate,
  currency,
  email,
  name
) => {
  return async (dispatch) => {
    dispatch(authStartLoading());
    firestore
      .collection("users")
      .doc(userId)
      .set({
        hourlyRate: hourlyRate,
        currency: currency,
        verified: true,
        email: email,
        name: name,
        delete: false,
      })
      .then(() => {
        dispatch(authSetHourlyRate(hourlyRate, currency, name));
        dispatch(authStopLoading());
      })
      .catch((error) => {
        console.error("Error creating user document", error);
        dispatch(authStopLoading());
      });
  };
};

export const authSetName = (name) => {
  return {
    type: actionTypes.AUTH_SET_NAME,
    name: name,
  };
};
