import React from "react";

import classes from "./Select.module.css";

const Select = (props) => {
  return (
    <div
      className={`${classes.Input} ${props.className}`}
      style={props.noError ? { marginBottom: 0 } : null}
    >
      <label className={classes.Label}>{props.label}</label>
      <select {...props.config}>
        {props.options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.displayValue}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Select;
