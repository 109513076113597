import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import classes from "./NavigationItems.module.css";
import NavigationItem from "../NavigationItem/NavigationItem";

import defaultAvatar from "../../../assets/default_avatar.svg";
import logo from "../../../assets/logo.svg";
import FolderIcon from "../../UI/FolderIcon/FolderIcon";
import TimesheetsIcon from "../../../assets/icons/timesheets.svg";

const NavigationItems = (props) => {
  const { t } = useTranslation();
  return (
    <div className={classes.Container}>
      <div className={classes.Logo}>
        <NavLink to="/" exact onClick={props.itemClicked}>
          <img src={logo} alt="Time Tracker" />
        </NavLink>
      </div>
      <ul className={classes.NavigationItems}>
        {/* Temporarily commented out blank pages */}

        {/* <NavigationItem link="/" exact clicked={props.itemClicked}>
        {t("dashboard")}
      </NavigationItem> */}
        <NavigationItem link="/timesheets" clicked={props.itemClicked}>
          <img className={classes.Icon} src={TimesheetsIcon} alt="Total time" />
          <>{t("timesheets")}</>
        </NavigationItem>
        <NavigationItem link="/projects" clicked={props.itemClicked}>
          <FolderIcon color="#086b72" className={classes.Icon} />
          {t("projects")}
        </NavigationItem>
        {/* <NavigationItem link="/activity" clicked={props.itemClicked}>
        {t("activity")}
      </NavigationItem> */}
        <NavigationItem link="/profile" clicked={props.itemClicked} profile>
          <img
            src={props.profilePhoto ? props.profilePhoto : defaultAvatar}
            alt=""
          />
          {props.userName}
        </NavigationItem>
      </ul>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userName: state.auth.name,
    profilePhoto: state.auth.profilePhotoUrl,
  };
};

export default connect(mapStateToProps)(NavigationItems);
