/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router";

import * as actions from "../../store/actions/";
import { firestore } from "../../firebase";
import Timer from "../Timer/Timer";
import Spinner from "../../components/UI/Spinner/Spinner";

const Dashboard = (props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    document.title = t("dashboard");
    // Get last timesheet for timer
    if (!props.timesheets) {
      let timesheets = [];
      firestore
        .collection("users")
        .doc(props.userId)
        .collection("timesheets")
        .orderBy("startTime", "desc")
        .limit(1)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            timesheets.push(doc.data());
          });
          props.onTimesheetsStore(timesheets);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, []);

  let content = <Spinner />;
  if (!loading) {
    content = <Timer />;
  }
  return (
    <>
      <h1>{t("dashboard")}</h1>
      {content}
      {/* Temporal redirect */}
      <Redirect to="/timesheets" />
    </>
  );
};

const mapStateToProps = (state) => ({
  userId: state.auth.uid,
  timesheets: state.timesheets.timesheets,
});

const mapDispatchToProps = (dispatch) => ({
  onTimesheetsStore: (timesheets) =>
    dispatch(actions.timesheetsStore(timesheets)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
