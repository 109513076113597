import currencyList from "./currency.json";

export const timestampToTime = (timestamp) => {
  var date = new Date(timestamp);
  var hours = date.getUTCHours();
  var minutes = "0" + date.getMinutes();
  var seconds = "0" + date.getSeconds();

  var formattedTime =
    hours + ":" + minutes.substr(-2) + ":" + seconds.substr(-2);
  return formattedTime;
};

export const timestampToMoney = (timestamp, hourlyRate, currency) => {
  const money = (timestamp / 1000 / 60 / 60) * hourlyRate;
  const decimal_digits = currencyList[currency].decimal_digits;
  return money.toFixed(decimal_digits);
};

export const currencySelectOptions = () => {
  const currencyArray = [];
  for (let key in currencyList) {
    currencyArray.push({
      value: key,
      displayValue:
        currencyList[key].name_plural + " - " + currencyList[key].symbol,
    });
  }
  return currencyArray;
};
