import React from "react";

import classes from "./Error.module.css";

const Error = (props) => (
  <p className={`${classes.Error} ${props.className}`} style={props.style}>
    {props.children}
  </p>
);

export default Error;
