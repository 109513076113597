import * as actionTypes from "../actions/actionTypes";

const initialState = {
  startTime: null,
  taskName: "",
  project: "",
  day: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TIMER_START:
      return {
        ...state,
        startTime: action.startTime,
        taskName: action.taskName,
        project: action.project,
        day: action.day,
      };

    case actionTypes.TIMER_STOP:
      return {
        ...state,
        startTime: null,
        taskName: "",
        project: "",
        day: null,
      };

    default:
      return state;
  }
};

export default reducer;
