import React from "react";
import classes from "./NavigationItem.module.css";
import { NavLink } from "react-router-dom";

const navigationItem = (props) => {
  const itemClasses = [classes.NavigationItem];
  if (props.profile) {
    itemClasses.push(classes.Profile);
  }
  return (
    <li className={itemClasses.join(" ")} onClick={props.clicked}>
      <NavLink
        to={props.link}
        activeClassName={classes.active}
        exact={props.exact}
      >
        {props.children}
      </NavLink>
    </li>
  );
};

export default navigationItem;
