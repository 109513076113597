import React from "react";
import classes from "./SimpleInput.module.css";

const SimpleInput = (props) => (
  <div
    className={`${classes.Input} ${props.className}`}
    style={props.noError ? { marginBottom: 0 } : null}
  >
    <label className={classes.Label}>{props.label}</label>
    <input
      className={
        props.invalid
          ? `${classes.InputElement} ${classes.Invalid}`
          : classes.InputElement
      }
      {...props.config}
    />
    {!props.noError ? (
      <span className={classes.Error}>{props.errorMessage}</span>
    ) : null}
  </div>
);

export default SimpleInput;
