import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCn91MsBPAY2A2NV_dpod38Ka8hjiPw8Xc",
  authDomain: "time-tracker-49b4f.firebaseapp.com",
  databaseURL: "https://time-tracker-49b4f.firebaseio.com",
  projectId: "time-tracker-49b4f",
  storageBucket: "time-tracker-49b4f.appspot.com",
  messagingSenderId: "829459912435",
  appId: "1:829459912435:web:565ac770bff7f786998986",
  measurementId: "G-1663E3CXD3",
};

firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const firestore = firebase.firestore();

const googleProvider = new firebase.auth.GoogleAuthProvider();
const githubProvider = new firebase.auth.GithubAuthProvider();

export const signInWithGoogle = () => {
  auth.signInWithRedirect(googleProvider);
};

export const signInWithGithub = () => {
  auth.signInWithRedirect(githubProvider);
};

export const generateUserDocument = async (user, additionalData) => {
  if (!user) return;
  const userRef = firestore.doc(`users/${user.uid}`);
  const snapshot = await userRef.get();
  if (!snapshot.exists) {
    const { email, displayName, photoURL } = user;
    try {
      await userRef.set({
        displayName,
        email,
        photoURL,
        ...additionalData,
      });
    } catch (error) {
      console.error("Error creating user document", error);
    }
  }
  return getUserDocument(user.uid);
};

const getUserDocument = async (uid) => {
  if (!uid) return null;
  try {
    const userDocument = await firestore.doc(`users/${uid}`).get();
    return {
      uid,
      ...userDocument.data(),
    };
  } catch (error) {
    console.error("Error fetching user", error);
  }
};
