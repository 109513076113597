import React, { useState } from "react";
import { connect } from "react-redux";

import Sidebar from "../../components/Navigation/Sidebar/Sidebar";
import NavigationItems from "../../components/Navigation/NavigationItems/NavigationItems";
import classes from "./Layout.module.css";
import Drawer from "../../components/Navigation/Drawer/Drawer";

import MenuRoundedIcon from "@material-ui/icons/MenuRounded";

const Layout = (props) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const onDrawerOpenHandler = () => {
    setDrawerOpen(true);
  };

  const onDrawerCloseHandler = () => {
    setDrawerOpen(false);
  };

  let menu = null;
  if (props.isVerified) {
    menu = (
      <>
        <Drawer
          open={drawerOpen}
          onOpen={onDrawerOpenHandler}
          onClose={onDrawerCloseHandler}
        >
          <NavigationItems itemClicked={onDrawerCloseHandler} />
        </Drawer>
        <Sidebar>
          <NavigationItems />
        </Sidebar>
      </>
    );
  }

  let hamburger = null;
  if (props.isVerified) {
    hamburger = (
      <MenuRoundedIcon
        className={classes.Hamburger}
        onClick={onDrawerOpenHandler}
        style={{ fontSize: 42 }}
      />
    );
  }

  return (
    <div className={classes.Layout}>
      {menu}
      <div className={classes.Container}>
        {hamburger}
        {props.children}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isVerified: state.auth.verified,
    userName: state.auth.name,
  };
};

export default connect(mapStateToProps)(Layout);
