import React from "react";
import classes from "./Spinner.module.css";

const Spinner = () => (
  <div className={classes.SpinnerContainer}>
    <div className={[classes.Pl, classes.Hourglass].join(" ")}></div>
  </div>
);

export default Spinner;
