import * as actionTypes from "./actionTypes";
import { firestore } from "../../firebase";

export const timerStartState = (timestamp, taskName, project, day) => {
  return {
    type: actionTypes.TIMER_START,
    startTime: timestamp,
    taskName: taskName,
    project: project,
    day: day,
  };
};

export const timerStopState = () => {
  return {
    type: actionTypes.TIMER_STOP,
  };
};

export const timerStart = (userId, project, task, timestamp, day) => {
  return async (dispatch) => {
    dispatch(timerStartState(timestamp, task, project, day));
    firestore
      .collection("users")
      .doc(userId)
      .collection("timesheets")
      .doc(timestamp.toString())
      .set({
        project: project,
        task: task,
        startTime: timestamp,
        day: day,
      })
      .catch(function (error) {
        console.error("Error writing startTime: ", error);
      });
  };
};

export const timerStop = (userId, startTime, stopTime, task, project) => {
  return (dispatch) => {
    dispatch(timerStopState());
    firestore
      .collection("users")
      .doc(userId)
      .collection("timesheets")
      .doc(startTime.toString())
      .update({
        stopTime: stopTime,
        task: task,
        project: project,
      })
      .catch(function (error) {
        console.error("Error writing stopTime: ", error);
      });
  };
};
