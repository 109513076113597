import * as actionTypes from "../actions/actionTypes";

const initialState = {
  projects: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PROJECTS_STORE:
      return { ...state, projects: action.projects };

    case actionTypes.PROJECTS_ADD:
      return {
        ...state,
        projects: {
          ...state.projects,
          [action.project.id]: {
            name: action.project.name,
            color: action.project.color,
            hourlyRate: action.project.hourlyRate,
            currency: action.project.currency,
            totalTime: 0,
          },
        },
      };

    case actionTypes.PROJECTS_REMOVE:
      const projects = { ...state.projects };
      delete projects[action.projectId];
      return { ...state, projects: projects };

    case actionTypes.PROJECTS_UPDATE:
      const updatedProjects = { ...state.projects };
      updatedProjects[action.projectId] = {
        ...updatedProjects[action.projectId],
        ...action.projectData,
      };
      return { ...state, projects: updatedProjects };

    case actionTypes.PROJECTS_INCREASE_TIME:
      const projectIncreaseData = {
        ...state.projects[action.projectId],
      };
      projectIncreaseData.totalTime += action.time;
      return {
        ...state,
        projects: {
          ...state.projects,
          [action.projectId]: projectIncreaseData,
        },
      };

    case actionTypes.PROJECTS_DECREASE_TIME:
      const projectData = {
        ...state.projects[action.projectId],
      };
      projectData.totalTime -= action.time;
      return {
        ...state,
        projects: {
          ...state.projects,
          [action.projectId]: projectData,
        },
      };

    default:
      return state;
  }
};

export default reducer;
