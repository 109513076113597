import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import { auth, firestore } from "./firebase";
import * as actions from "./store/actions/";
import Layout from "./containers/Layout/Layout";
import Dashboard from "./containers/Dashboard/Dashboard";
import Spinner from "./components/UI/Spinner/Spinner";
import asyncComponent from "./hoc/asyncComponent/asyncComponent";

const asyncTimesheets = asyncComponent(() => {
  return import("./containers/Timesheets/Timesheets");
});

const asyncProjects = asyncComponent(() => {
  return import("./containers/Projects/Projects");
});

const asyncSingleProject = asyncComponent(() => {
  return import("./containers/SingleProject/SingleProject");
});

const asyncLogin = asyncComponent(() => {
  return import("./containers/Login/Login");
});

const asyncHourlyRate = asyncComponent(() => {
  return import("./containers/HourlyRate/HourlyRate");
});

const asyncProfile = asyncComponent(() => {
  return import("./containers/Profile/Profile");
});

const asyncActivity = asyncComponent(() => {
  return import("./containers/Activity/Activity");
});

class App extends Component {
  componentDidMount = () => {
    this.props.onStartLoading();
    auth.onAuthStateChanged((userAuth) => {
      let userData = null;
      if (userAuth) {
        userData = { ...userAuth };
        if (!userData.displayName && this.props.name) {
          userData.displayName = this.props.name;
        }
      }
      this.props.onStartLoading();
      this.props.onAuthUpdateState(
        userData
          ? {
              uid: userData.uid,
              email: userData.email,
              name: userData.displayName,
              profilePhotoUrl: userData.photoURL,
            }
          : null
      );
      if (userData) {
        let userLoaded = false;
        let projectsLoaded = false;
        // Get user data
        firestore
          .collection("users")
          .doc(userData.uid)
          .get()
          .then((doc) => {
            if (doc.exists) {
              if (doc.data().verified) {
                this.props.onSetHourlyRate(
                  doc.data().hourlyRate,
                  doc.data().currency,
                  doc.data().name
                );
              }
            }
            userLoaded = true;
            if (projectsLoaded) {
              this.props.onStopLoading();
            }
          });

        // Get projects
        let projects = {};
        firestore
          .collection("users")
          .doc(userData.uid)
          .collection("projects")
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc, index) => {
              projects[doc.id] = { ...doc.data() };
            });
            this.props.onProjectsStore(projects);
            projectsLoaded = true;
            if (userLoaded) {
              this.props.onStopLoading();
            }
          });
      }
    });
  };

  render() {
    let content = <Spinner />;
    if (!this.props.loading) {
      content = (
        <Switch>
          <Route path="/timesheets" component={asyncTimesheets} />
          <Route path="/projects/:id" component={asyncSingleProject} />
          <Route path="/projects" component={asyncProjects} />
          <Route path="/activity" component={asyncActivity} />
          <Route path="/profile" component={asyncProfile} />
          <Route path="/" exact component={Dashboard} />
          <Redirect to="/" />
        </Switch>
      );

      if (!this.props.isAuthorised) {
        content = (
          <Switch>
            <Route path="/login" component={asyncLogin} />
            <Redirect from="/" to="/login" />
          </Switch>
        );
      }

      if (this.props.isAuthorised && !this.props.isVerified) {
        content = (
          <Switch>
            <Route path="/hourly-rate" component={asyncHourlyRate} />
            <Redirect from="/" to="/hourly-rate" />
          </Switch>
        );
      }
    }

    return <Layout>{content}</Layout>;
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthorised: state.auth.uid !== null,
    defaultHourlyRate: state.auth.hourlyRate,
    loading: state.auth.loading,
    isVerified: state.auth.verified,
    projects: state.projects.projects,
    name: state.auth.name,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuthUpdateState: (authData) =>
      dispatch(actions.authUpdateState(authData)),
    onStartLoading: () => dispatch(actions.authStartLoading()),
    onSetHourlyRate: (hourlyRate, currency, name) =>
      dispatch(actions.authSetHourlyRate(hourlyRate, currency, name)),
    onStopLoading: () => dispatch(actions.authStopLoading()),
    onTimerStart: (userId, project, task, timestamp) =>
      dispatch(actions.timerStart(userId, project, task, timestamp)),
    onProjectsStore: (projects) => dispatch(actions.projectsStore(projects)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
