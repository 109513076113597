/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from "react";

import classes from "./ColorSelector.module.css";
import ColorModal from "./ColorModal/ColorModal";
import checkIcon from "../../../assets/icons/check.svg";

const colorSelector = (props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [activeColor, setActiveColor] = useState(props.value);

  const openModal = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const onSetColor = (event, color) => {
    setActiveColor(color);
    props.changed(event);
    closeModal();
  };

  return (
    <div className={props.className}>
      <button
        className={classes.Color}
        style={{
          background: activeColor,
          marginLeft: 12,
          marginRight: 0,
        }}
        onClick={openModal}
        type="button"
      />
      <ColorModal show={modalVisible} modalClosed={closeModal}>
        {props.values.map((color) => {
          return (
            <button
              key={color}
              className={classes.Color}
              style={{ background: color }}
              type="button"
              value={color}
              onClick={(event) => onSetColor(event, color)}
            >
              {color === activeColor ? (
                <div className={classes.Active}>
                  <img src={checkIcon} alt="Active" />
                </div>
              ) : null}
            </button>
          );
        })}
      </ColorModal>
    </div>
  );
};

export default colorSelector;
