import React from "react";

import classes from "./Sidebar.module.css";

const sidebar = (props) => (
  <div className={classes.Sidebar}>
    <div className={classes.Content}>{props.children}</div>
  </div>
);
export default sidebar;
