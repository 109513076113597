import * as actionTypes from "../actions/actionTypes";

const initialState = {};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TASKS_STORE:
      return { ...state, [action.project]: action.tasks };

    case actionTypes.TASKS_ADD:
      return {
        ...state,
        [action.project]: [...state[action.project], action.task],
      };

    case actionTypes.TASKS_DELETE:
      const newTasks = [...state[action.project]];
      newTasks.splice(action.index, 1);
      return {
        ...state,
        [action.project]: newTasks,
      };

    case actionTypes.TASKS_INCREASE_TIME:
      const newTask = { ...state[action.project][action.index] };
      newTask.time += action.time;
      const tasks = [...state[action.project]];
      tasks[action.index] = newTask;
      return {
        ...state,
        [action.project]: tasks,
      };

    case actionTypes.TASKS_DECREASE_TIME:
      const newTaskDecrease = { ...state[action.project][action.index] };
      newTaskDecrease.time -= action.time;
      const tasksDecrease = [...state[action.project]];
      tasksDecrease[action.index] = newTaskDecrease;
      return {
        ...state,
        [action.project]: tasksDecrease,
      };

    default:
      return state;
  }
};

export default reducer;
