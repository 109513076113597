import React from "react";
import CSSTransition from "react-transition-group/CSSTransition";

import classes from "./Backdrop.module.css";

const backdrop = (props) => (
  <CSSTransition
    mountOnEnter
    unmountOnExit
    in={props.show}
    timeout={200}
    classNames={{
      enter: classes.BackdropHide,
      enterActive: classes.BackdropShow,
      exitActive: classes.BackdropHide,
    }}
  >
    <div className={classes.Backdrop} onClick={props.clicked}></div>
  </CSSTransition>
);

export default backdrop;
