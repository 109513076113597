import { ClickAwayListener, Portal } from "@material-ui/core";
import React from "react";
import CSSTransition from "react-transition-group/CSSTransition";

import classes from "./ColorModal.module.css";

const colorModal = (props) => (
  <CSSTransition
    mountOnEnter
    unmountOnExit
    in={props.show}
    timeout={200}
    classNames={{
      enter: classes.ModalClosed,
      enterActive: classes.ModalOpen,
      exitActive: classes.ModalClosed,
    }}
  >
    <>
      <Portal>
        <div className={classes.ParentCloseBlocker}></div>
      </Portal>
      <ClickAwayListener onClickAway={props.modalClosed}>
        <div className={classes.Modal}>{props.children}</div>
      </ClickAwayListener>
    </>
  </CSSTransition>
);

export default colorModal;
