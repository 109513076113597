import * as actionTypes from "../actions/actionTypes";

const initialState = {
  uid: null,
  email: null,
  name: null,
  profilePhotoUrl: null,
  hourlyRate: null,
  currency: null,
  loading: true,
  verified: false,
};

const authUpdateState = (state, action) => {
  if (!action.authData) {
    return {
      ...state,
      uid: null,
      email: null,
      name: null,
      profilePhotoUrl: null,
      hourlyRate: null,
      currency: null,
      verified: false,
      loading: false,
    };
  }
  return {
    ...state,
    ...action.authData,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_UPDATE_STATE:
      return authUpdateState(state, action);

    case actionTypes.AUTH_START_LOADING:
      return { ...state, loading: true };

    case actionTypes.AUTH_STOP_LOADING:
      return { ...state, loading: false };

    case actionTypes.AUTH_SET_HOURLY_RATE:
      return {
        ...state,
        hourlyRate: action.hourlyRate,
        currency: action.currency,
        name: action.name,
        verified: true,
      };

    case actionTypes.AUTH_SET_NAME:
      return { ...state, name: action.name };

    default:
      return state;
  }
};

export default reducer;
