import * as actionTypes from "../actions/actionTypes";

const initialState = {
  timesheets: null,
  enough: false,
};

const timesheetsAdd = (state, action) => {
  const timesheets = [...state.timesheets];
  if (timesheets[0] && !timesheets[0].stopTime) {
    timesheets[0] = action.timesheet;
  } else {
    timesheets.unshift(action.timesheet);
  }
  return { ...state, timesheets: timesheets };
};

const timesheetsDelete = (state, action) => {
  const timesheets = state.timesheets.filter(
    (timesheet) => timesheet.startTime !== action.timestamp
  );
  return { ...state, timesheets: timesheets };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TIMESHEETS_STORE:
      return { ...state, timesheets: action.timesheets };

    case actionTypes.TIMESHEETS_ENOUGH:
      return { ...state, enough: true };

    case actionTypes.TIMESHEETS_ADD:
      return timesheetsAdd(state, action);

    case actionTypes.TIMESHEETS_DELETE:
      return timesheetsDelete(state, action);

    default:
      return state;
  }
};

export default reducer;
