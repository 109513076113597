import * as actionTypes from "./actionTypes";

export const projectsStore = (projects) => ({
  type: actionTypes.PROJECTS_STORE,
  projects: projects,
});

export const projectsAdd = (project) => ({
  type: actionTypes.PROJECTS_ADD,
  project: project,
});

export const projectsUpdate = (projectId, projectData) => ({
  type: actionTypes.PROJECTS_UPDATE,
  projectId: projectId,
  projectData: projectData,
});

export const projectsRemove = (projectId) => ({
  type: actionTypes.PROJECTS_REMOVE,
  projectId: projectId,
});

export const projectsIncreaseTime = (projectId, time) => ({
  type: actionTypes.PROJECTS_INCREASE_TIME,
  projectId: projectId,
  time: time,
});

export const projectsDecreaseTime = (projectId, time) => ({
  type: actionTypes.PROJECTS_DECREASE_TIME,
  projectId: projectId,
  time: time,
});
