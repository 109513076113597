export const TIMER_START = "TIMER_START";
export const TIMER_STOP = "TIMER_STOP";
//export const TIMER_PROJECT = "TIMER_PROJECT";

export const AUTH_UPDATE_STATE = "AUTH_UPDATE_STATE";
export const AUTH_START_LOADING = "AUTH_START_LOADING";
export const AUTH_STOP_LOADING = "AUTH_STOP_LOADING";
export const AUTH_SET_HOURLY_RATE = "AUTH_SET_HOURLY_RATE";
export const AUTH_STORE_HOURLY_RATE = "AUTH_STORE_HOURLY_RATE";
export const AUTH_SET_NAME = "AUTH_SET_NAME";

export const TIMESHEETS_STORE = "TIMESHEETS_STORE";
export const TIMESHEETS_ENOUGH = "TIMESHEETS_ENOUGH";
export const TIMESHEETS_ADD = "TIMESHEETS_ADD";
export const TIMESHEETS_DELETE = "TIMESHEETS_DELETE";

export const PROJECTS_STORE = "PROJECTS_STORE";
export const PROJECTS_ADD = "PROJECTS_ADD";
export const PROJECTS_REMOVE = "PROJECTS_REMOVE";
export const PROJECTS_UPDATE = "PROJECTS_UPDATE";
export const PROJECTS_INCREASE_TIME = "PROJECTS_INCREASE_TIME";
export const PROJECTS_DECREASE_TIME = "PROJECTS_DECREASE_TIME";

export const TASKS_STORE = "TASKS_STORE";
export const TASKS_ADD = "TASKS_ADD";
export const TASKS_DELETE = "TASKS_DELETE";
export const TASKS_INCREASE_TIME = "TASKS_INCREASE_TIME";
export const TASKS_DECREASE_TIME = "TASKS_DECREASE_TIME";
