import React from "react";
import classes from "./Button.module.css";

const Button = (props) => (
  <button
    type={props.type}
    className={[classes.Button, classes[props.btnType], props.className].join(
      " "
    )}
    disabled={props.disabled}
    onClick={props.clicked}
  >
    {props.children}
  </button>
);

export default Button;
