import * as actionTypes from "./actionTypes";
import { firestore } from "../../firebase";

export const timesheetsStore = (timesheets) => {
  return {
    type: actionTypes.TIMESHEETS_STORE,
    timesheets: timesheets,
  };
};

export const timesheetsEnough = () => {
  return {
    type: actionTypes.TIMESHEETS_ENOUGH,
  };
};

export const timesheetsAdd = (timesheet) => {
  return {
    type: actionTypes.TIMESHEETS_ADD,
    timesheet: timesheet,
  };
};

export const timesheetsDeleteState = (timestamp) => {
  return {
    type: actionTypes.TIMESHEETS_DELETE,
    timestamp: timestamp,
  };
};

export const timesheetsDelete = (userId, timestamp) => {
  return async (dispatch) => {
    dispatch(timesheetsDeleteState(timestamp));
    firestore
      .collection("users")
      .doc(userId)
      .collection("timesheets")
      .doc(timestamp.toString())
      .delete()
      .catch((error) => {
        console.error("Error removing timesheet: ", error);
      });
  };
};
