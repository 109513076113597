import * as actionTypes from "./actionTypes";

export const tasksStore = (project, tasks) => {
  return {
    type: actionTypes.TASKS_STORE,
    project: project,
    tasks: tasks,
  };
};

export const tasksAdd = (project, task) => {
  return {
    type: actionTypes.TASKS_ADD,
    project: project,
    task: task,
  };
};

export const tasksDelete = (project, index) => {
  return {
    type: actionTypes.TASKS_DELETE,
    project: project,
    index: index,
  };
};

export const tasksIncreaseTime = (project, index, time) => {
  return {
    type: actionTypes.TASKS_INCREASE_TIME,
    project: project,
    index: index,
    time: time,
  };
};

export const tasksDecreaseTime = (project, index, time) => {
  return {
    type: actionTypes.TASKS_DECREASE_TIME,
    project: project,
    index: index,
    time: time,
  };
};
