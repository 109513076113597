import React from "react";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import "./Drawer.css";

const drawer = (props) => {
  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
  return (
    <SwipeableDrawer
      className="drawer"
      open={props.open}
      onOpen={props.onOpen}
      onClose={props.onClose}
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
    >
      {props.children}
    </SwipeableDrawer>
  );
};

export default drawer;
