/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

import { timestampToTime, timestampToMoney } from "../../shared/utility";
import * as actions from "../../store/actions";
import currencyList from "../../shared/currency.json";
import useInterval from "../../hooks/useInterval";

import Modal from "../../components/UI/Modal/Modal";
import CreateProject from "../CreateProject/CreateProject";
import Card from "../../components/UI/Card/Card";
import Button from "../../components/UI/Button/Button";
import startIcon from "../../assets/icons/start.svg";
import stopIcon from "../../assets/icons/stop.svg";
import SimpleInput from "../../components/UI/SimpleInput/SimpleInput";

import classes from "./Timer.module.css";
import Select from "../../components/UI/Select/Select";

const Timer = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const { register, handleSubmit, setValue } = useForm({
    mode: "onChange",
  });

  const [currency, setCurrency] = useState(props.currency);
  const [showModal, setShowModal] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [currentMoney, setCurrentMoney] = useState(
    timestampToMoney(0, props.hourlyRate, currency)
  );
  const [started, setStarted] = useState(false);
  const [selectOptions, setSelectOptions] = useState([
    { value: "no_project", displayValue: t("no_project") },
    { value: "add_project", displayValue: t("add_project") },
  ]);

  useEffect(() => {
    // Load projects for dropdown
    if (props.projects) {
      loadProjects();
    }

    if (props.timesheets[0]) {
      if (!props.timesheets[0].stopTime) {
        props.onTimerStartState(
          props.timesheets[0].startTime,
          props.timesheets[0].task,
          props.timesheets[0].project,
          props.timesheets[0].day
        );
      }
    }

    // If last task in unfinished
    if (props.startTime) {
      continueTimer();
    }
  }, []);

  // Update dropdown on projects list change
  useEffect(() => {
    loadProjects();
  }, [props.projects]);

  // If project started outside of the Timer component
  // For example in a timesheet
  useEffect(() => {
    if (props.startTime && !started) {
      continueTimer();
    }
  }, [props]);

  useInterval(() => {
    if (started) {
      count();
    }
  }, 1000);

  const loadProjects = () => {
    const previousOptions = selectOptions;
    // Get "No project" entry
    const options = [{ ...previousOptions[0] }];
    for (let key in props.projects) {
      const newElement = {
        value: key,
        displayValue: props.projects[key].name,
      };
      if (!options.includes(newElement)) {
        options.push(newElement);
      }
    }
    // Push "Add project" entry
    options.push(previousOptions[previousOptions.length - 1]);
    // Update state
    setSelectOptions(options);
  };

  const continueTimer = () => {
    // Load task name and project from Redux
    setValue("taskName", props.taskName);
    setValue("project", props.project);
    if (props.projects[props.project]) {
      setCurrency(props.projects[props.project].currency);
    }
    setStarted(true);
    count();
  };

  const count = () => {
    let time = null;
    // Get current time rounded to seconds
    const now = new Date();
    now.setMilliseconds(0);
    time = props.startTime ? now.getTime() - props.startTime : 0;
    let hourlyRate = props.hourlyRate;
    let currency = props.currency;
    // Load hourly rate and currency from project if project is set
    if (props.projects[props.project]) {
      hourlyRate = props.projects[props.project].hourlyRate;
      setCurrency(props.projects[props.project].currency);
    }
    const money = timestampToMoney(time, hourlyRate, currency);
    setCurrentTime(time);
    setCurrentMoney(money);
  };

  const startTimer = (data) => {
    setStarted(true);
    // Get current time rounded to seconds
    const startTime = new Date();
    startTime.setMilliseconds(0);
    const timestamp = startTime.getTime();
    startTime.setHours(0, 0, 0, 0);
    const day = startTime.getTime() / 100000;
    props.onTimerStart(
      props.userId,
      data.project,
      data.taskName,
      timestamp,
      day
    );
  };

  // Makes stopping timer (by submitting form) available from parent container
  useImperativeHandle(ref, () => ({
    stopTimer() {
      // Submit form
      handleSubmit(toggleTimerHandler)();
      return currentTime;
    },
  }));

  const stopTimer = (data) => {
    // Get current time rounded to seconds
    const now = new Date();
    now.setMilliseconds(0);
    const stopTime = now.getTime();
    props.onTimerStop(
      props.userId,
      props.startTime,
      stopTime,
      data.taskName,
      data.project
    );
    const totalTime = stopTime - props.startTime;
    const money = timestampToMoney(totalTime, props.hourlyRate, props.currency);
    props.onTimesheetAdd({
      day: props.day,
      task: data.taskName,
      project: data.project,
      startTime: props.startTime,
      stopTime: stopTime,
      totalTime: totalTime,
      money: money,
    });
    // Update project total time in Redux
    if (props.projects[data.project]) {
      props.projectsIncreaseTime(data.project, totalTime);
    }
    // Reset timer
    setCurrentTime(0);
    setCurrentMoney(timestampToMoney(0, props.hourlyRate, currency));

    // Check if tasks of this project are downloaded to Redux for SingleProject page
    if (props.tasks[data.project]) {
      let taskIndex = null;
      props.tasks[data.project].some((task, index) => {
        if (task.name === data.taskName) {
          console.log(task);
          taskIndex = index;
          return true;
        }
        return false;
      });
      if (taskIndex || taskIndex === 0) {
        // If task exists, increase it's time
        props.tasksIncreaseTime(data.project, taskIndex, totalTime);
      } else {
        // If the task is new, add it to Tasks
        props.tasksAdd(data.project, {
          name: data.taskName,
          time: totalTime,
          newTask: false,
        });
      }
    }
    setStarted(false);
  };

  const toggleTimerHandler = (data) => {
    if (props.startTime) {
      stopTimer(data);
    } else {
      startTimer(data);
    }
  };

  const createProject = (value) => {
    if (value === "add_project") {
      // Open modal to create a new project
      setShowModal(true);
    } else if (value !== "no_project") {
      // Set currency of a project
      setCurrency(props.projects[value].currency);
    } else {
      // Set default currency if no project is selected
      setCurrency(props.currency);
    }
    return true;
  };

  const createProjectSubmit = (project) => {
    setShowModal(false);
    // Push the new project to select options
    const options = [{ ...selectOptions[0] }];
    options.push({ value: project.id, displayValue: project.name });
    options.push(selectOptions[selectOptions.length - 1]);
    setSelectOptions(options);
    // Auto select the new project and set currency
    setValue("project", project.id.toString());
    setCurrency(project.currency);
  };

  const formattedTime = timestampToTime(currentTime);
  let timerIcon = startIcon;
  let timerText = t("start_timer");
  if (props.startTime) {
    timerIcon = stopIcon;
    timerText = t("stop_timer");
  }

  const createProjectCancel = () => {
    setShowModal(false);
    setValue("project", "no_project");
  };

  return (
    <>
      <Card className={classes.Timer}>
        <form onSubmit={handleSubmit(toggleTimerHandler)}>
          <SimpleInput
            config={{
              name: "taskName",
              ref: register(),
              type: "text",
              placeholder: t("task_name"),
            }}
            className={classes.TaskName}
          />
          <Select
            config={{
              name: "project",
              ref: register({ validate: createProject }),
            }}
            className={classes.Project}
            options={selectOptions}
          />
          <div className={classes.Right}>
            <div className={classes.Indicators}>
              <p>{formattedTime}</p>
              <p>
                {currentMoney + " "}
                {currencyList[currency].symbol}
              </p>
            </div>
            <Button btnType={props.startTime ? "red" : "green"}>
              <img src={timerIcon} alt="" />
              {timerText}
            </Button>
          </div>
        </form>
      </Card>
      <Modal
        show={showModal}
        modalClosed={createProjectCancel}
        title={t("create_project")}
      >
        <CreateProject onSubmit={createProjectSubmit} />
      </Modal>
    </>
  );
});

const mapStateToProps = (state) => {
  return {
    userId: state.auth.uid,
    currency: state.auth.currency,
    hourlyRate: state.auth.hourlyRate,
    startTime: state.timer.startTime,
    taskName: state.timer.taskName,
    project: state.timer.project,
    day: state.timer.day,
    projects: state.projects.projects,
    timesheets: state.timesheets.timesheets,
    tasks: state.tasks,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onTimerStart: (userId, project, task, timestamp, day) =>
      dispatch(actions.timerStart(userId, project, task, timestamp, day)),
    onTimerStartState: (timestamp, taskName, project, day) =>
      dispatch(actions.timerStartState(timestamp, taskName, project, day)),
    onTimerStop: (userId, startTime, stopTime, task, project) =>
      dispatch(actions.timerStop(userId, startTime, stopTime, task, project)),
    onTimesheetAdd: (timesheet) => dispatch(actions.timesheetsAdd(timesheet)),
    projectsIncreaseTime: (project, time) =>
      dispatch(actions.projectsIncreaseTime(project, time)),
    tasksStore: (project, tasks) =>
      dispatch(actions.tasksStore(project, tasks)),
    tasksAdd: (project, task) => dispatch(actions.tasksAdd(project, task)),
    tasksIncreaseTime: (project, taskName, time) =>
      dispatch(actions.tasksIncreaseTime(project, taskName, time)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(Timer);
