import React, { useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

import * as actions from "../../store/actions/";
import { currencySelectOptions } from "../../shared/utility";
import { firestore } from "../../firebase";

import Button from "../../components/UI/Button/Button";
import SimpleInput from "../../components/UI/SimpleInput/SimpleInput";
import Select from "../../components/UI/Select/Select";
import Error from "../../components/UI/Error/Error";
import ColorSelector from "../../components/UI/ColorSelector/ColorSelector";

import classes from "./CreateProject.module.css";

const CreateProject = (props) => {
  const { t } = useTranslation();
  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      name: props.id ? props.projects[props.id].name : null,
      hourlyRate: props.id
        ? props.projects[props.id].hourlyRate
        : props.defaultHourlyRate,
      currency: props.id
        ? props.projects[props.id].currency
        : props.defaultCurrency,
    },
    mode: "onChange",
  });
  const selectOptions = currencySelectOptions();
  const colorValues = [
    "#F13838",
    "#FF7A00",
    "#FFB800",
    "#E7BA1D",
    "#D2D635",
    "#E5F900",
    "#89FF2C",
    "#04CB00",
    "#00871E",
    "#14CCAB",
    "#52D5FF",
    "#00A2E8",
    "#4026E0",
    "#3F00A7",
    "#9E00FF",
    "#C700CB",
    "#F4A0EC",
    "#ABACAB",
    "#994900",
    "#000107",
  ];
  const [color, setColor] = useState(
    props.id ? props.projects[props.id].color : "#04cb00"
  );

  const notInProjects = (value) => {
    const projects = { ...props.projects };
    if (props.id) {
      delete projects[props.id];
    }
    let isValid = true;
    for (const key in projects) {
      if (projects[key].name === value) {
        isValid = false;
        break;
      }
    }
    return isValid;
  };

  const onSubmit = (data) => {
    const project = {
      name: data.name,
      color: color,
      hourlyRate: data.hourlyRate,
      currency: data.currency,
    };

    // Generate new ID if creting new project or use existing if editing project
    project.id = props.id ? props.id : Date.now().toString();

    // Execute passed function, for example closing modal window
    props.onSubmit(project);

    const projectQuery = firestore
      .collection("users")
      .doc(props.userId)
      .collection("projects")
      .doc(project.id);

    if (props.id) {
      // Update project in Redux
      props.onProjectsUpdate(project.id, project);
      // Update project in Firebase
      projectQuery.update(project).catch((error) => {
        console.error("Error writing project: ", error);
      });
    } else {
      // Store new project in Redux
      props.onProjectsAdd(project);
      // Store new project in Firestore
      projectQuery
        .set({
          ...project,
          totalTime: 0,
        })
        .catch((error) => {
          console.error("Error writing project: ", error);
        });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={classes.Inputs}>
        <div style={{ display: "flex", alignItems: "flex-end" }}>
          <SimpleInput
            className={classes.name}
            label={t("name")}
            config={{
              autoFocus: true,
              name: "name",
              type: "text",
              placeholder: t("name"),
              ref: register({
                required: true,
                validate: notInProjects,
              }),
            }}
            invalid={errors.name ? "error" : null}
          />

          <ColorSelector
            values={colorValues}
            value={color}
            className={classes.color}
            changed={(event) => setColor(event.target.value)}
          />
        </div>
        {errors.name && errors.name.type === "required" ? (
          <Error className={classes.Error}>{t("required")}</Error>
        ) : null}
        {errors.name && errors.name.type === "validate" ? (
          <Error className={classes.Error}>{t("project_exists")}</Error>
        ) : null}

        <SimpleInput
          label={t("default_hourly_rate")}
          className={classes.rate}
          config={{
            name: "hourlyRate",
            type: "number",
            placeholder: t("default_hourly_rate"),
            ref: register({
              required: true,
              valueAsNumber: true,
            }),
          }}
          invalid={errors.hourlyRate ? "error" : null}
        />

        <Select
          className={classes.currency}
          config={{
            name: "currency",
            ref: register(),
          }}
          options={selectOptions}
        />
        {errors.hourlyRate && errors.hourlyRate.type === "required" ? (
          <Error className={classes.Error}>{t("required")}</Error>
        ) : null}
      </div>
      <Button btnType="fluid">
        {props.id ? t("update_project") : t("create_project")}
      </Button>
    </form>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state.auth.uid,
    defaultHourlyRate: state.auth.hourlyRate,
    defaultCurrency: state.auth.currency,
    projects: state.projects.projects,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onProjectsAdd: (project) => dispatch(actions.projectsAdd(project)),
    onProjectsUpdate: (projectId, projectData) =>
      dispatch(actions.projectsUpdate(projectId, projectData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateProject);
