import React from "react";

const FolderIcon = (props) => (
  <svg
    viewBox="0 0 21 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={props.className}
  >
    <path
      d="M0 13V2C0 0.895431 0.895429 0 2 0H7.34471C7.87131 0 8.37667 0.207692 8.75108 0.577995L10.6934 2.49893C11.0678 2.86923 11.5731 3.07692 12.0997 3.07692H19C20.1046 3.07692 21 3.97236 21 5.07692V13C21 14.1046 20.1046 15 19 15H2C0.895431 15 0 14.1046 0 13Z"
      fill={props.color}
    />
  </svg>
);

export default FolderIcon;
