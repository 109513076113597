/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useCallback } from "react";
import CSSTransition from "react-transition-group/CSSTransition";

import classes from "./Modal.module.css";
import Backdrop from "../Backdrop/Backdrop";
import close from "../../../assets/icons/close.svg";

const Modal = (props) => {
  let title = "";
  if (props.title) {
    title = <h2 className={classes.Title}>{props.title}</h2>;
  }

  useEffect(() => {
    document.addEventListener("keydown", keyPressHandler, false);
    return () => {
      document.removeEventListener("keydown", keyPressHandler, false);
    };
  }, []);

  const keyPressHandler = useCallback((event) => {
    if (event.keyCode === 27) {
      props.modalClosed();
    }
  }, []);

  return (
    <>
      <Backdrop show={props.show} clicked={props.modalClosed} />
      <CSSTransition
        mountOnEnter
        unmountOnExit
        in={props.show}
        timeout={200}
        classNames={{
          enterActive: classes.ModalOpen,
          enterDone: classes.ModalOpen,
          exitActive: classes.ModalClosed,
        }}
      >
        <div className={classes.Modal}>
          <div className={classes.Header}>
            {title}
            <button onClick={props.modalClosed}>
              <img alt="close" src={close} />
            </button>
          </div>
          {props.children}
        </div>
      </CSSTransition>
    </>
  );
};

export default Modal;
